<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><g><rect fill="none" /></g><g><g><path d="M6,13h12c0.55,0,1-0.45,1-1l0,0c0-0.55-0.45-1-1-1H6c-0.55,0-1,0.45-1,1l0,0C5,12.55,5.45,13,6,13z M4,17h12 c0.55,0,1-0.45,1-1l0,0c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1l0,0C3,16.55,3.45,17,4,17z M7,8L7,8c0,0.55,0.45,1,1,1h12 c0.55,0,1-0.45,1-1l0,0c0-0.55-0.45-1-1-1H8C7.45,7,7,7.45,7,8z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconWays',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
